const imageUrls = {
    aboutClosingSection: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/aboutClosingSection.jpg?alt=media&token=9bb4a5c1-74c0-4bf8-b921-eca44b1cef4e',
    arthurCollage: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/Family%20Collage.jpg?alt=media&token=8f8326c1-19ca-4132-889b-f06f924bccfa',
    arthurBrittany: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/arthurbrittany.png?alt=media&token=00291f83-83eb-456f-8eaa-35ee5017b68d',
    brittany: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/BrittanyWeaver.png?alt=media&token=869e844b-ef45-4949-8327-072c87a3886a',
    brittanyBio: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/BrittanyWeaverBio.jpg?alt=media&token=fee964f0-da01-4239-a621-7c4b1a65b389',
    encounterBanner: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/EWCBanner.jpg?alt=media&token=f413d642-8fe3-45ff-a786-e88ec0053261',
    juliannaBio: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/juliannaBio.jpg?alt=media&token=10352c07-be89-4311-9c77-30bc06e8570e',
    julianna: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/JuliannaJamesGilbert.png?alt=media&token=fcbf2bca-c893-458d-b53f-49211b419317',
    lavonBio: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/lavonBio.jpg?alt=media&token=d9a00e28-586f-4456-9198-1060e001b372',
    lavon:'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/lavonBio.jpg?alt=media&token=8f12785c-619a-45e2-8459-1a7001300e1b',
    logo: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/Logo.png?alt=media&token=30c86145-ab12-4252-a0bc-f624cb5edb7b',
    anthony: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/anthonyAlexander.png?alt=media&token=cd171b1b-a446-4171-8aa2-2d9e9536615c',
    anthonyBio: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/anthonyAlexanderBio.jpg?alt=media&token=02daa52f-06f1-4a0f-aefe-8437bf4b0f22',
    arthurStanding: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/arthurStanding.png?alt=media&token=5def74a1-dc3b-42af-b3c5-4a517fe89e4f',
    churchService: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/churchService.jpg?alt=media&token=38451a35-67b2-4d59-9ae8-a58ef8b4cf22',
    contact: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/contact.jpg?alt=media&token=0a28c573-3036-4676-a112-29e754f7dce6',
    friends: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/friends4.jpg?alt=media&token=a248ee05-9ca0-475f-b3f4-2e33a4be1fce',
    fullLogo: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/encounterLogo.png?alt=media&token=94c0e205-b438-401b-9ecb-4fa2e04a0c18',
    leadersPage: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/leadershipPhoto.png?alt=media&token=b0ec0506-85ce-4931-b600-f754ca251d0d',
    leadersPhoto: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/leadershipPhotonone.jpg?alt=media&token=6865db28-393b-489a-9959-dcae1087a3cb',
    lensFlare: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/lensFlare.jpg?alt=media&token=06924de5-4a1f-4bb1-aacd-3ba3fbd243cd',
    livestream: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/livestream.jpg?alt=media&token=c9475795-d49e-459c-a3fe-fc0ba1101199',
    mensMinistry: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/mensMinistry.jpg?alt=media&token=749fd81c-a2e8-472f-8d37-2710b425faaf',
    ministryOne: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/ministryOne.jpg?alt=media&token=ea13d8f1-32b5-40ac-9a0c-42a469de56cc',
    planner: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/planner.png?alt=media&token=0ca73e6b-f9c2-4d6a-92e5-d60e9d4c11ec',
    prayerRequests: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/prayerRequests.jpg?alt=media&token=b4a86bbb-f79c-45bc-ac60-bb3f655bd667',
    rainboxLion: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/rainbowLionCut.png?alt=media&token=4a985a42-cd56-43aa-ae1f-80c62422ea53',
    signature: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/pastorSignature.png?alt=media&token=1f592cd5-09ac-4f29-8ba3-adacc9514403',
    strongerTogether: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/watchSermonTile.jpg?alt=media&token=5564edfa-de4e-49d6-914c-834048a6d486',
    tarneice: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/tarneiceMines.png?alt=media&token=2c0ca0ea-7efd-4f9a-980f-3df1630c9b99',
    tarneiceBio: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/tarneiceBio.jpg?alt=media&token=03722aa8-7dcb-443c-9019-1c0d68bcff07',
    tithing: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/givingImage.jpg?alt=media&token=dcb8d5e1-1807-4081-8bc3-5de3268ce8bc',
    womensMinistry: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/womensMinistry.jpg?alt=media&token=dc8ceb08-07fc-4310-b2ed-759a00880b55',
    concert: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/worshipBackground.jpg?alt=media&token=282e4ea7-9804-4e15-8061-c7e8df527633',
    worshipPerson: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/worshipPerson.jpg?alt=media&token=536d06d6-11b6-40fe-8339-c07190c76740',
    youth: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/youthMinistry.jfif?alt=media&token=8ba92f53-ed85-451e-9c5d-ef474e5b3361',
    watchNight: 'https://firebasestorage.googleapis.com/v0/b/the-encounter-454ba.appspot.com/o/watch%20night%20Prayer%20Flyer.jpg?alt=media&token=7a75a88c-a030-4fdc-8092-1be783be1248'
}

export default imageUrls;